/* Typography.css */
@font-face {
    font-family: 'DoctorGlitch';
    src: url('../../Fonts/Doctor\ Glitch.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
     font-family: 'ExtremeGlitch';
     src: url('../../Fonts/Extreme\ Glitch.otf') format('opentype');
     font-weight: normal;
     font-style: normal;
}

@font-face {
    font-family: 'DeniroGlitch';
    src: url('../../Fonts/Deniro\ Glitch.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-weight: 800;
    font-style: normal;
}


/* styles.css */
.menu-item {
    font-family: "nougat-script", sans-serif;
}

/* Apply the first custom font to headings */
h1 {
    font-family: "nougat-script", sans-serif;
    letter-spacing: 0.13em; /* Adjust the value as needed */
    /* font-size: 2.4em; */
    /* margin: 0vh; */
}

h2, h3 {
    font-family: "nougat-script", sans-serif;
    line-height: 1.5; /* 1.5 times the font size */

}

.noDestination {
    font-family: 'ExtremeGlitch', sans-serif !important;

}

.noDestinationTitle {
    font-family: 'DoctorGlitch', sans-serif !important;

}

/* Apply the second custom font to paragraph text */
body {
     font-family: 'gill-sans-nova', sans-serif;
     font-weight: 450; /* Light weight */
}

p {
    font-family: 'gill-sans-nova', sans-serif;
    font-weight: 450; 

}

strong {
     font-family: 'nougat-script', sans-serif;
}

/* Base font size */
 
 /* Font size classes */
 .importantBody {
     font-size: 3.5rem; /* Initially 20px */
 }

 .primaryBody{
     font-size: 2.7rem; /* Initially 28px */

 }
 
 .secondaryBody {
     font-size: 2.0rem; /* Initially 28px */
 }
 
 .fineBody {
     font-size: 1.3rem; /* Initially 35px */
 }
 
 /* Tablet Version */
@media (max-width: 900px) {
    html {
        font-size: 14px; /* Smaller base font for tablet */
    }

    .importantBody {
        font-size: 3rem; /* Adjust for smaller screens */
    }

    .primaryBody {
        font-size: 2.4rem; 
    }

    .secondaryBody {
        font-size: 1.6rem; 
    }

    .fineBody {
        font-size: 1.1rem;
    }

    /* Additional layout adjustments for tablet */
    .menu-item {
        font-size: 2rem; /* Smaller menu item for tablet */
    }

    h2, h3 {
        font-size: 1.5rem; /* Adjust headings */
    }

}

/* Phone Version */
@media (max-width: 600px) {
    html {
        font-size: 12px; /* Even smaller base font for phone */
    }

    .importantBody {
        font-size: 2.5rem; /* Smaller text for phones */
    }

    .primaryBody {
        font-size: 2.0rem;
    }

    .secondaryBody {
        /* font-size: 1.3rem; */
    }

    .fineBody {
        /* font-size: 1.8rem; */
    }

    /* Additional layout adjustments for phone */
    .menu-item {
        font-size: 2.5rem; /* Smaller menu item for phone */
    }

    h2 {
        font-size: 1.5rem; /* Adjust headings for phones */
    }

    h3 {
        font-size: 2.7rem; /* Adjust headings for phones */
    }

    h1 {
        /* font-size: 2.4rem; */

    }
}

.white {
    color: white;
}