/* Base styles for the expandable box */
.expandable-box {
    width: 75%; /* 45% of the viewport width */
    padding: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: auto;
    max-width: 80vh;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    overflow: hidden;
    background-color: black;
    border-radius: 30px;
    transition: max-height 0.5s ease-in-out; /* Smooth transition for max-height */
    color: white;

}

/* Adjustments for very large screens */

/* Adjustments for smaller screens like tablets */

/* Initial state */
.extra-content {
    opacity: 0; /* Initially hidden */
    max-height: 0; /* Start with no height */
    overflow: hidden; /* Prevent content from showing */
    transition: opacity 0.5s ease-out, max-height 0.5s ease-in-out; /* Smooth transitions for opacity and height */
}

/* Expanded state */
.expandable-box.expanded .extra-content {
    display: block;
    opacity: 1; /* Fade in when expanded */
    max-height: 31rem; /* Enough height to show the content */
    transition: opacity 0.5s ease-out, max-height 0.5s ease-in-out;

}

.see-more-less {
    margin-top: 1rem;
    margin-bottom: 0;
    color: white;
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.box-summary{
    margin-bottom: 0;
    margin-top: 0;
}
.see-more-less.faded {
    opacity: 0.5;
}

.see-more-less:hover {
    opacity: 0.8;
}

.box-content{
    width: auto;
}