/*Sidemenu.css*/
.menu-container {
    position: fixed;
    top: 50%;
    right: 2vh;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 1rem;
    z-index: 1000; /* Higher value to ensure it is on top */

}


.hamburger-react {
    position: fixed !important;
    top: 0;
    right: 0;
    /* transform: translateY(-50%); */
    /* display: flex;
    flex-direction: column; */
    /* align-items: center; */
    width: 48px;
    outline: none;
    /* color: white; */
    z-index: 1000; /* Ensure it's above other content */
    margin: 3vh;
    transition: color 0.5s ease; /* Transition effect */

}

/* Dynamic styles based on section */
.hamburger-color-why .hamburger-react,
.hamburger-color-values .hamburger-react,
.hamburger-color-connect .hamburger-react {
    color: black;
}

.hamburger-color-home .hamburger-react {
    color: white;
}

.menu-item {
    margin: 5vh; /* Spacing between menu items */
    margin-left: 1vh;
    margin-right: 1vh;
    text-decoration: none;
    transition: color 0.5s ease; /* Transition effect */

}

.aboutMenu{
    transition-delay: 0.5s; /* Add a 0.5 second delay */

}

.menu-item.white.active {
    color: white; /* Active home color */
}
.menu-item.white.inactive {
    color: hsla(0, 0%, 100%, 0.5); /* InActive home color */
}

.menu-item.black.active {
    color: black /* Active why color */
}

.menu-item.black.inactive {
    color: hsla(0, 0%, 0%, 0.5); /* iNActive why color */
}

.menu-item:hover {
    transform: scale(1.1); /* Slight scaling effect for the active item */
}
  
.phone-menu {
    position: fixed; /* Overlay everything */
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw; /* Full viewport width */
    background-color: rgba(0, 0, 0, 0.8); /* 75% transparent black background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's on top of everything */
    opacity: 0; /* Initially hidden */
    pointer-events: none; /* Prevent interaction when hidden */
    transition: opacity 0.5s ease; /* Smooth fade-in effect */
}
/* When the menu is open */
.phone-menu.open {
    opacity: 1; /* Visible when the menu is open */
    pointer-events: all; /* Enable interaction when open */
  
}

.menu-email{
    background-color: transparent !important;
    border: 2px solid white !important; /* Ensure border style is defined */
}

.menu-email-button {
    color: #000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 0.5rem !important;
    background-color: rgb(0, 0, 0, 0);
    outline: none;
    transition: transform 0.3s ease;
    border: 2px solid white !important; /* Ensure border style is defined */
    border-radius: 40px; /* top-left, top-right, bottom-right, bottom-left */
    width: 100%;
    max-width: 33vh;
    padding-left: 2vh;
    padding-right: 2vh;
}

@media (max-width: 1024px) {
    .menu-container {
        position: fixed;
        margin-left: auto;
        margin-right: auto;
        top: 47%;
        align-items: center;
        /* bottom: 50; */
        left: 0;
        right: 0;
        margin-bottom: 0;
        transform: translateY(-50%);    
    }

    .menu-email-submission{
        position: fixed;
        z-index: 1000; /* Higher value to ensure it is on top */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        bottom: 15vh;
    }

    .menu-item {
        font-size: 3.5rem;

    }
}