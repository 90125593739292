@import url("https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&family=IBM+Plex+Mono&display=swap");

/* *,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

:root {
  --color-background: rgb(60, 66, 55);
  --color-foreground: rgb(230, 225, 215);
  --color-accent: rgb(200, 180, 160);
  --font-primary: "Inter", sans-serif;
  --font-secondary: "IBM Plex Mono", monospace;
  --margin: 32px;
  --gutter: 16px;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: var(--font-primary);
  background: var(--color-background);
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1001;
}

.progress-container {
  width: 300px;
  height: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: var(--color-foreground);
}

.percentage {
  position: fixed;
  bottom: var(--margin);
  right: var(--margin);
  font-family: "nougat-script";
  font-weight: 700;
  font-size: 25rem;
  line-height: 0.8;
  color: var(--color-foreground);
  opacity: 0.1;
}

.text-container {
  height: 3em;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
  width: 200px;
}

.loading-text {
  font-family: "nougat-script";
  font-weight: 300;
  color: var(--color-foreground);
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  position: absolute;
  width: 100%;
  text-align: center;
}

.loading-text.initial {
  transform: translateY(0);
}

.loading-text.complete {
  transform: translateY(100%);
}

.loading-text .char {
  display: inline-block;
}

.content {
  padding: var(--margin);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-foreground);
  background: var(--color-background);
  visibility: hidden;
  z-index: 1;
}

.content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.content p {
  font-size: 1.2rem;
  overflow: hidden;
}

.content .char {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
}

/* Add these classes for the stagger animation */
.preloader-item {
  opacity: 1;
  transform: translateY(0);
  z-index: 50;
  position: absolute;
}
