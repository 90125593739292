/* About.css */

#about {
    /* height: 400vh; Adjust depending on the content */
    /* display: flex;
    flex-direction: column; */
    /* align-items: end; */
    background-color: white; /* Default background color */
    transition: background-color 2s ease; /* Transition effect for background color */
    overflow: hidden; /* Prevents content from overflowing outside of the about section */
    width: 100vw;
}

/* CSS for about div when vision section is in viewport */
#about.about-vision-active {
    background-color: black; /* New background color when active */
}

#about.landingVideo-vision-active {
    background-color: black; /* New background color when active */
}

.about-content {
    height: 100%;
    width: 100%; /* Full width of the container */
    /* height: 100%; */
    /* display: flex;
    place-items: center; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; */
    position: relative;
    margin: 0 auto; /* Center the content within the section */
    min-height: 100%; /* Prevent it from expanding too much */
    overflow: visible;
    
}

.about-content {
    display: grid;
    grid-template-rows: 1fr auto 1fr; /* Three rows: first and last take up equal space, middle takes the height it needs */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%; /* Full width of the container */
    justify-content: center; /* Align content to start vertically */
    /* right: 0; */

}

.expandable-box {
    grid-row: 2; /* Middle row */
    align-self: end; /* Align to the bottom of its row */
    justify-content: start;
    align-self: center; /* Center in the middle row */
    transition: max-height 0.5s ease-in-out; /* Smooth transition for expansion */
}

.box-footer {
    grid-row: 3; /* Third row */
    align-self: end; /* Align to the bottom of its row */
    justify-content: start;

    align-self: start; /* Align to the top of the third row */
}

.left{
    display: flex;
    justify-content: center; /* Align content to start vertically */
    flex-direction: column;

}

@media (max-width: 1024px) {
    .about-content {
        grid-template-rows: 1fr auto 1fr; /* Allow rows to expand naturally */
    }

    .about-section {
        height: auto; /* Allow height to expand as needed */
    }

    .expandable-box {
        max-height: 100%; /* Expand fully on mobile */
    }
}

#about.about-vision-active .about-content {
    align-items: flex-start;  /* Align to start when active */
}

#why, #values {
    color: white;
    height: calc(var(--vh, 1vh) * 100);
}

#landingVideo{
    color: black;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 10;
}

#vision {
    color: black;
    height: calc(var(--vh, 1vh) * 100);

}

.about-section {
    width: 100vw; /* Set to 100% for full width */
    /* padding: 20px; */
    position: relative;
    /* height: auto; */
    /* height: 100vh; */
    /* height: 100vh; */
    /* grid-template-columns: repeat(1, 1fr); 
    gap: 0; No gaps between items */
    height: auto;
    overflow-y: auto; /* Allow scrolling if content overflows */
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: flex-start; /* Align items to the top */
    justify-content: center; /* Ensure content is centered vertically */
    overflow: visible; /* Ensure content can grow */

}

.box-header {
    font-size: 4em;
    margin: auto;
    margin-top: 0;
    margin-bottom: 1vw;
    height: auto;
    text-align: center;
    grid-row: 1; /* First row */
    align-self: end; /* Align to the bottom of its row */
    justify-content: start;

}

.box-footer{
    text-align: center;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
}


.carousel-header {
    font-size: 2em;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px; /* Adjust this value to reduce space below the header */
    height: auto;
    text-align: center;

}

.black{
    color: black;
}

.white{
    color: white;
}

@media (max-width: 900px) {

    .box-header {
        margin-bottom: 2vh;
    }

}