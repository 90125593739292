/* Store Section Styles */
#store {
  /* height: calc(var(--vh, 1vh) * 100); */
  background-color: rgb(98, 85, 52);    
  display: flex;
  flex-direction: column;
  /* align-items: end; */
  width: 100vw;
  z-index: 10;

}

#store-content {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative; /* Background is relative to this section */
}

.storeContentBox{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  z-index: 10;
}

#store-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  transition: background 0.5s ease, opacity 0.5s ease;
  background-color: rgba(0, 0, 0, 0.1);
}
/* Background Styling */
.store-background {
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100vw; /* Full width to span the entire viewport */
  height: calc(var(--vh, 1vh) * 100);
  display: flex; /* Flexbox layout */
  flex-direction: row; /* Align children in a row */
  justify-content: space-between; /* Space out children */
  z-index: 0; /* Background layer */
  top: 0;
  left: 0;
  position: absolute; /* Fixed background positioning */
  overflow: hidden; /* Prevent overflow of the images */
}

.store-background > * {
  height: 100vh; /* Full viewport height for each child */
}

.store-background > :first-child {
  align-self: flex-start; /* Align the first child to the start of the row */
}

.store-background > :last-child {
  align-self: flex-end; /* Align the last child to the end of the row */
}

/* Title Styling */
.item-title {
  text-align: center;
  z-index: 3;
  font-size: 1.5rem;
  position: absolute;
  top: 5vh;
}

/* Store Item Layout */
.store-item {
  position: absolute;
  top: auto;
  bottom: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 5rem 0; */
  z-index: 10;
}

/* Vinyl Display Layout */
.vinyl-display {
  position: relative; /* Parent container for both front and back */
  /* width: 40vh; */
  /* height: 40vh; */
  z-index: 13;
  justify-content: center;
  align-items: center;
  display: flex;

}

/* Track List Styling */
.track-list {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease-in, transform 1s ease-in-out; /* Animation for fade and movement */
  transform: translateX(0); /* Initial position */
  z-index: 3;
}

/* Checkout Button Styling */
.checkout-button {
  text-align: center;
  border-radius: 10px;
  border: 3px solid white; /* Combines border-color, border-width, and border-style */
  padding: 1.5vh;
  /* padding-top: 0.2vw;
  padding-bottom: 0.2vw; */
  font-size: 1.2rem;
  display: inline-block;
  color: white;
  background-color: transparent;
  z-index: 3;
}

/* Item World Styling */
.item-world {
  /* width: 30vh; */
  position: absolute;
  bottom: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  z-index: 100;
}

.item-world img {
  display: block;
  height: auto; /* Maintain aspect ratio */
  /* width: 100%; */
  scale: 0.8;
  margin: 0 auto; /* Center horizontally */
}

/* Vinyl Front and Back Covers */
.vinyl-front {
  position: relative; /* Positioned relatively within vinyl-display */
  z-index: 13;
  width: auto;
  height: auto;
  transform: translateX(0); /* Default position */
  transition: transform 1s ease-in-out; /* Smooth movement */
}

.vinyl-front-cover {
  position: relative; /* Positioned relative to the disk */
  z-index: 13; /* On top of the disk */
  width: 100%;
  height: 100%;
}

.vinyl-front-disk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  object-fit: cover;
  transform-origin: 55% 48%; /* Custom origin for rotation */
  transition: transform 0.5s ease-in-out; /* Smooth rotation */
}

.vinyl-back-cover {
  position: absolute;
  z-index: 12;
  object-fit: cover;
  transform: translateX(0); /* Default position */
  transition: transform 1s ease-in-out; /* Smooth movement */
}

/* Track Item Styling */
.track-item {
  opacity: 0.5; /* Default opacity */
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  font-size: 1.5rem;
  white-space: nowrap; /* Prevent text wrapping */
}

.track-item:hover {
  transform: scale(1.05); /* Slight increase in size on hover */
}

.track-item.current {
  opacity: 1; /* Highlight current track */
  font-size: 1.7rem; /* Larger font for current track */
}

/* Store Animation on View */
.store-item.in-view .track-list {
  opacity: 1; /* Fade in */
  transform: translateX(100%); /* Slide in */
}

.store-item.in-view .vinyl-front {
  transform: translateX(-25%); /* Move to the left */
}

.store-item.in-view .vinyl-back-cover {
  transform: translateX(25%); /* Move to the right */
}

/* Keyframes for Vinyl Disk Rotation */
@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.vinyl-front-disk.vinyl-rotate {
  animation: rotate 3s linear infinite; /* Continuous rotation */
}

@media (max-width: 1624px) {
  .store-item.in-view .track-list {
    opacity: 1; /* Fade in */
    transform: translateX(70%); /* Slide in */
  }

  .vinyl-display {
    transform: scale(0.8); /* Slight increase in size on hover */
  
  }
}


/* Media Queries */
@media (max-width: 1024px) {
  /* Adjust for smaller screens */
  .store-item.in-view .track-list,
  .store-item.in-view .vinyl-front,
  .store-item.in-view .vinyl-back-cover {
      transform: translateX(0%); /* No movement on smaller screens */
  }

  .store-item{
    padding: 0;
  }

  .vinyl-display {
    transform: scale(1.2); /* Slight increase in size on hover */
  
  }

  .item-title {
    text-align: center;
    z-index: 3;
    font-size: 1.5rem;
    position: absolute;
    top: 10vh;
    
  }
}

/* Media Queries */
@media (max-width: 430px) {

  .store-item{
    padding: 0;
  }

  .vinyl-display {
    transform: scale(0.6); /* Slight increase in size on hover */
  
  }
}
