
body, html {
  height: 100%; /* Full height */
  margin: 0; /* Remove default margin */
  scroll-behavior: smooth; /* Enable smooth scrolling */
  scroll-snap-type: y mandatory; /* Enable scroll snapping vertically */
  overflow: hidden; /* Prevent default scrolling */

  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}
:root {
  --vh: 100%; /* Default value */
}

.full-height {
  height: calc(var(--vh) * 100); /* Dynamically set height */
}
.safe-area {
  padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
  min-height: -webkit-fill-available;

}

.full-height {
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: env(safe-area-inset-bottom, 0);
}


.App{
  margin: 0; /* Ensure there's no default margin interfering */
  box-sizing: border-box; /* Avoid sizing issues */
  padding: 0;
}



.videoBlockInfo{
  z-index: 10 ;
  position: absolute;
  display: flex;
  justify-content: end;
  align-items: start;
  flex-direction: column;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 11vh;
  padding-left: 11vh;

}

.videoIcon img{
  height: 15vh;
  width: auto;
}

#landing-Page {
  overflow: hidden; 
  /* overflow-y: auto; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: end; */
  scroll-snap-type: y mandatory; /* Apply snapping to the landing page container */
}

.video-section,
.about-section,
#store,
#connect {
  scroll-snap-align: start;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out; /* Adjust duration and easing */
}



.video-item {
  position: relative;
  z-index: 0; /* Ensure the video is behind the overlay */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire section */
}

.landingVideo {
  height: calc(var(--vh, 1vh) * 100);
}


#video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the overlay is on top of the video */
  pointer-events: none; /* Prevent the overlay from blocking interactions */
  transition: background 0.5s ease, opacity 0.5s ease; /* Smooth transition for background and opacity */
}

#about-section {
  align-items: center;
}

#vision {
  /* Assuming 'vision' is the second section's ID */
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Aligns children to the start of the cross axis */
}

.homeShade {
  background-color: hsla(0, 0%, 0%, 0.20); /* Black with 50% opacity */
}

.aboutShade {
  background-color: hsla(0, 0%, 0%, 0); /* Black with 50% opacity */
}

.storeShade {
  background-color: hsla(0, 0%, 0%, 0.0); /* Black with 50% opacity */
}
.connectShade {
  background-color: hsla(0, 0%, 0%, 0); /* Black with 50% opacity */
}

/* Animation for fading in from the bottom */
@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* This class will be added when the element comes into view */
.fade-in-bottom {
  opacity: 0; /* Start invisible */
  animation: fadeInFromBottom 1.5s ease forwards; /* Animation to fade in and slide up */
  
}

@media (max-width: 1024px) {
  body {
    scroll-snap-type: y mandatory; /* Enable scroll snapping */
  }


  .about-section, #connect {
    overflow-y:auto; /* Enable scrolling on mobile */
    scroll-snap-align: start;
    scroll-snap-stop: always; /* Ensure snapping stops at each section */
    height: 100vh; /* Full height for each section */
    scroll-snap-type: y mandatory; /* Enable scroll snapping */
    
  } 

  .videoBlockInfo{
  
    z-index: 10 ;
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    height: calc(var(--vh, 1vh) * 100);
    margin-bottom: 5vh;
    padding-bottom: calc(var(--vh, 1vh) * 100)/10;
    padding-left: 0vh;
    text-align: center;
  }

}