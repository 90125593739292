/* Footer.css */
.footer {
  position: fixed;
  bottom: 3vh; /* Padding from the bottom */
  right: 4vh;
  display: flex;
  align-items: center;
  gap: 15px; /* Spacing between images */
  z-index: 1000; /* Higher value to ensure it is on top */

}
  
.footer img {
  width: 30px; /* Adjust the size of the images */
  height: auto;
  cursor: pointer;
  transition: opacity 0.4s ease-in; /* Smooth transition on hover */
  margin: 1vh; /* Spacing between menu items */

}

.footer img:hover {
  opacity: 0.7; /* Slight opacity change on hover */
}


@media (max-width: 1024px) {
  .footer {
    position: fixed;
    margin-left: 10vw;
    margin-right: 10vw;
    top: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px; /* Spacing between images */
    width: 80%;
  }
    
  .footer img {
    width: 7.2vw; /* Adjust the size of the images */
    max-width: 4vh;
    height: auto;
    cursor: pointer;
    transition: opacity 0.4s ease-in; /* Smooth transition on hover */
    margin: 0.5vw; /* Spacing between menu items */
    z-index: 1000; /* Higher value to ensure it is on top */

  }
}
  
  

