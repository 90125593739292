/*CarouselBoxAlignLeft.css*/
.carousel-box {
    margin-top: 0;
    margin-bottom: 0;
    text-align: start;
    overflow: hidden;
    color: black;
    transition: max-height 0.5s ease-in-out; /* Smooth transition for max-height */
    width: auto;
}

.carousel-box {
    overflow: visible; /* Let Swiper manage the overflow */
    position: relative; /* Required for positioning the pseudo-elements */
    align-items: center;
    justify-content: center;
}


.author{
    text-align: center;
    margin-left: 12vw;
    margin-top: 0;
    width: auto;
}


.swiper {
    padding: 2em 0; /* Top and bottom padding only, remove side paddings */
    /* margin: 0 !important; */
    /* width: 80vw; */
    overflow: hidden; /* Let Swiper manage the overflow */
}

.swiper-container {
    position: relative; /* Ensures that absolute positioning within this container is relative to it */
    min-height: 300px; /* Minimum height to ensure there's space for pagination */
    overflow: visible; /* Ensures pagination is visible */
}

.swiper-wrapper{
    align-items: center;

}

.swiper-slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.carousel-content{
    width: 90%; 

}

.carousel-wrapper {
    width: 70%; /* 45% of the viewport width */
    /* min-width: 82vw; */
    border-radius: 0 30px 30px 0; /* top-left, top-right, bottom-right, bottom-left */
    background-color: white;
    max-width: 150vh;

    margin-right: auto;
    overflow: hidden; /* Optional, ensures nothing spills out if not needed */
}

.swiper::before, .swiper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px; /* Width of the fading effect */
    pointer-events: none; /* Prevents interference with click events on the carousel */
    z-index: 10; /* Make sure this is above your slides but below your navigation buttons if applicable */
}

.swiper::before {
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.swiper::after {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
}

@media (max-width: 768px) {
    .swiper::before, .swiper::after {
        width: 30px; /* Smaller fade effect on smaller screens */
    }
}

@media (max-width: 900px) {
    .author{
        margin-left: 30vw;
    }
    .carousel-wrapper {
        width: 80%; /* 45% of the viewport width */
    }
}

.swiper-pagination {
    margin-bottom: 1.5rem !important;  
    margin-top: 1.5rem !important;  

    position: relative !important; 
    width: 100%  !important; 
    z-index: 9999; /* High z-index to ensure it's on top */
}

.swiper-pagination-bullet {
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50% !important;
    display: inline-block;
    margin: 0 4px;
    opacity: 1 !important; /* Force bullets to be fully opaque */

}

.left-swpier .swiper-pagination-bullet{
    background-color: white !important;
    border: 2px solid black !important; /* Ensure border style is defined */

}

.left-swpier .swiper-pagination-bullet-active {
    background: black !important; /* Change for visibility */
    border-color: black !important;

}

.swiper-container {
    min-height: 300px; 
}
