/* Footer.css */
.homeLogo {
  display: flex;
    align-items: center; /* Vertically center the icon and text */
    justify-content: center; /* Center align the icon and text horizontally */
    position: fixed;
    top: 1.4vw; /* Padding from the bottom */
    justify-content: center;
    right: auto;
    left: auto;
    width: 100%;
    display: flex;
    z-index: 1002; /* Higher value to ensure it is on top */
    /* background-color: black; */
  }
    
.yusefLogo img {
  display: flex;
  align-items: center; /* Center align the content */
  justify-content: center;
  flex-direction: row;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease; /* Smooth transition for translateY */
  opacity: 0;
  cursor: pointer;
}

.visible{
  opacity: 1 !important; /* Fully visible */

}
  
.yusefText {
  margin-left: 10px; /* Add spacing between icon and text */
  opacity: 1; /* Default state: visible */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

  
@media (max-width: 1024px) {
  .yusefLogo {
    align-items: center; /* Center align the content */
    justify-content: center;
    flex-direction: row;
  }
    
  .yusefLogo img {
    height: auto;
    cursor: pointer;
    z-index: 1000; /* Higher value to ensure it is on top */

  }
  .homeLogo{
    scale: 0.70;
    top: 2vw; /* Padding from the bottom */

  }
}
    
/* HeadLogo.css */
@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}

.fade-in {
  animation: fadeInFromLeft 0.5s ease-in-out forwards;
}

.fade-out {
  animation: fadeOutToLeft 0.5s ease-in-out forwards;
}
