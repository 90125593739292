#home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300vw;
    position: relative;
    /* transform: translateX(-200vw); Moves the section 200vw to the left */
    overflow: hidden; /* Optional, ensures nothing spills out if not needed */
    height: calc(var(--vh, 1vh) * 100);

}
  
.video-section {
    min-height: 100vh; /* Full viewport height for each video */
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.video-item {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    
}