/* center carousel styling */

.center-carousel-wrapper {
    width: 95%; /* 45% of the viewport width */
    border-radius: 30px; /* top-left, top-right, bottom-right, bottom-left */
    background-color: black;
    max-width: 80vh;
    /* padding-left: 2em;
    padding-right: 2em; */
    overflow: hidden;
    position: relative; /* Required for positioning pseudo-elements */
    margin-left: auto;
    margin-right: auto;
    height: auto;
    transition: transform 0.6s ease;  /* Smooth transition for snapping */
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.center-carousel-box {
    margin-top: 0;
    margin-bottom: 0;
    text-align: start;
    overflow: visible;
    color: white;
    width: auto;
    align-items: start;
    justify-content: start;
    transition: transform 0.6s ease;  /* Smooth transition for snapping */

}

/* Target the pagination for the values page */
.center-swiper-pagination .child  {
    background: white !important;
}


.center-swiper {
    padding: 2em 0;
    padding-bottom: 0;
    /* margin: 0 !important; */
    width: 100%;
    overflow: hidden;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
}

.center-swiper-slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 90%; /*NEW CHANGES*/
    height: 100%;
}

.center-swiper-pagination {
    margin-bottom: 1.5rem !important;  
    margin-top: 1.5rem !important;  
    position: absolute !important; 
    /* width: auto  !important;  */
    z-index: 3; /* High z-index to ensure it's on top */
    display: flex !important;
    flex-direction: row;
    /* justify-content: center; */
    overflow: visible !important;
    margin: auto;
    justify-content: center; /* Center the pagination bullets */
    left: 50% !important;
}


.center-swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    display: inline-block;
    margin: 0 4px;
    opacity: 1 !important; /* Force bullets to be fully opaque */
    overflow: visible !important;
}

.center-swiper-pagination .swiper-pagination-bullet {
    border: 2px solid white !important; /* Ensure border style is defined */

}
/* Default pagination style */
/* Default pagination style */
.center-swiper-pagination .swiper-pagination-bullet-active {
    border: 2px solid white !important; /* Ensure border style is defined */    
    background-color: white !important; /* Active white color for values-page */
}

.values-page ~ .center-swiper-pagination .swiper-pagination-bullet-active {
    background-color: white !important; /* Active white color for values-page */
    border: 2px solid white !important; /* Ensure border style is defined */

}

.swiper-pagination-bullet-active-next-next{
    transform: scale(1) !important;
}
.swiper-pagination-bullet-active-next{
    transform: scale(1) !important;
}

.swiper-pagination-bullet-active-prev-prev{
    transform: scale(1) !important;
}
.swiper-pagination-bullet-active-prev{
    transform: scale(1) !important;
}

/* Fading effect for swiper container */
.center-carousel-wrapper::before,
.center-carousel-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    z-index: 10;
}

.center-carousel-wrapper::before {
    left: 0;
    background: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
}

.center-carousel-wrapper::after {
    right: 0;
    background: linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}



.center-carousel-content{
    text-align: center;
    width:fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    /* white-space: nowrap; / */
    padding-bottom: 2rem;
    
}


.center-carousel-content .first-item {
    align-self: flex-start; /* Align the first item to the top */
  }