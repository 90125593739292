#connect {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
}

#connect-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between cta (centered) and email (bottom) */
    align-items: center; /* Center items horizontally */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    min-width: 80vw;
}

.connect-cta {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center the CTA */
    justify-content: center; /* Vertically center the CTA within its parent */
    flex-grow: 1; /* Allow it to take up available space */
    width: 100%;
}

.email-submission {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center email submission fields horizontally */
    align-items: center; /* Vertically center the content inside the row */
    width: 100%
}

.email-box {
    padding: 1vh;
    padding-left: 2vh;
    border-radius: 2vw;
    border: 1px solid #000000;
    font-size: 1rem;
    width: 100%;
    /* height: 2vh; */
    max-width :30vh;
    font-family: "nougat-script", sans-serif;

}

@media (max-width: 1024px) {
    
    .email-submission {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center email submission fields horizontally */
        align-items: center; /* Vertically center the content inside the row */
        justify-content: space-between; /* Space between cta (centered) and email (bottom) */
    }

    .email-box{
        margin-bottom: 2vh
    }
}

.yosef-email {
    font-size: 1.5rem;
    margin-bottom: 3vh; /* Ensure it stays at the bottom with some spacing */
}




.email-box::placeholder {
    /* font-family: 'DoctorGlitch', sans-serif; */
    /* padding-left: 0.8vw; */
    font-family: 'nougat-script', sans-serif;

}

.email-box::content{
    font-family: "gill-sans-nova", sans-serif;
    /* padding-left: 0.8vw; */

}

.extreme-glitch {
    font-family: 'ExtremeGlitch';
}
  
.email-button {
    color: #000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    background-color: rgb(0, 0, 0, 0);
    outline: none; /* Removes any outline when focused */
    transition: transform 0.3s ease; /* Smooth transition effect */
}

.email-button:hover {
    transform: scale(1.1); /* Scale the element to 110% */
}

 .connect-title{
    font-size: 1.5rem;
    opacity: 0.8;
    margin-bottom: 1vh;
 }
